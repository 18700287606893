.ant-form-item {
  &.mb-0 {
    margin-bottom: 0;
  }
}

.ant-modal-title {
  margin: 0;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  left: -1px;
  right: 0px;
}

.error-message {
  display: block;
  color: $error;
  font-size: 0.9rem;
  margin: 8px 0 0 1px;
  font-weight: 300;
}

.progress_container {
  .ant-progress {
    position: fixed;
  }
}

.brief-container-wrapper {
  margin-left: -90px;
  border: 1px solid #d9d9d9;
}

.brief-layout-wrapper {
  width: calc(100vw - 220px);
  margin: 0 0 0 -110px;
  border: 1px solid #d9d9d9;

  .header-content {
    h3 {
      margin-right: 100px;
    }
  }

  .qu-brief-main {
    max-height: calc(100vh - 187px);
    min-height: calc(100vh - 187px);
  }

  .qu-channels-grid {
    .ant-col-md-6 {
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
  }
}

.qu-sidebar .sidebar-menu .ant-menu-item-selected {
  color: #6e8590 !important;
  font-weight: 400 !important;
}

.qu-sidebar .sidebar-menu .ant-menu-submenu-open .ant-menu-submenu-title {
  color: #6e8590 !important;
  font-weight: 400 !important;

  &:hover {
    color: $black !important;
  }
}

.qu-sidebar .sidebar-menu .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #6e8590 !important;
  font-weight: 400 !important;

  &:hover {
    color: $black !important;
  }
}

.qu-sidebar .sidebar-menu .active {
  color: $qblue !important;
  font-weight: 600 !important;
}

.qu-sidebar {
  flex: 0 0 240px !important;
  max-width: 240px !important;
  min-width: 240px !important;
  width: 240px !important;
  padding: 30px 0 30px 30px !important;
  overflow: hidden !important;
}

.ant-dropdown-menu-item {
  .discount-item {
    display: flex;
    align-items: center;

    svg {
      height: 16px;
      width: 16px;
      margin-right: 10px;
    }
  }
}

.barchart-height-stabilize {
  margin-bottom: -50px;
}

.qu-metrics-card {
  .ant-card-body {
    padding: 0;
  }

  .ant-card-grid {
    padding: 0;
  }
}

.currency_dropdown {
  width: 100px;
}

.cursorPointer {
  cursor: pointer;
}

.ant-notification {
  z-index: 1050 !important;
}

.product-sub-type {
  font-size: 14px;
}

.flexRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.customMediaServicesTablePills {
  padding: 4px;
  background: #d9d9d9;
  border-radius: 5px;
  white-space: nowrap;
  word-break: keep-all;
  display: inline-block;
  margin: 2px;
}

.calendar-month-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.calendar-container {
  min-width: calc(100vw - 320px);
  margin-bottom: 50px;
  margin-top: 20px;
  border-top: 1px solid #e9eaed;
  padding-top: 30px;
  padding-bottom: 30px;
}

.calendar-loader {
  min-width: calc(100vw - 200px);
  text-align: center;
  background: #f4f5f7;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  border-radius: 10px;
}

.calendar-status-span {
  color: whitesmoke;
  padding: 0px 10px;
  border-radius: 25px;
  color: whitesmoke;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  border: 2px solid transparent;
  opacity: 0.4;
}

.calendar-status-span:hover {
  opacity: 1;
}

.calendar-status-active {
  // border:2px solid black;
  box-shadow: 0px 1px 5px #999;
  opacity: 1;
}

// .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child),
// .fc-direction-ltr .fc-button-group > .fc-button:not(:first-child){
//   background: #e17055;
//     border: #fab1a0;
// }

.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  padding-left: 5px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin: 2px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding-right: 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 2px;
}

.fc-event-main {
  overflow-x: hidden;
}

.logo-block {
  display: none;
}

.qu-logo-new-container {
  height: 60px;
  background-color: white;
  margin-bottom: 40px;
}

.qu-logo-new {
  height: 60px;
  object-fit: contain;
  object-position: center;
  cursor: pointer;
}

.qu-details-aside {
  position: relative;
}

.services-form-controls {
  position: fixed;
  bottom: 0;
  width: 244px;
}

.workflow-container {
}

.workflow-element {
  display: inline-block;
  padding: 5px;
  margin-left: 5px;
  box-sizing: border-box;
  transform: skew(-8deg);
  user-select: none;
}

.success {
  background-color: green;
  color: white;
}

.incomplete {
  background-color: #a2a2a2;
  color: white;
}

.ant-modal-body {
  flex-direction: column;
}

.calendarLegend {
  padding-left: 10px;
}

.pending {
  background-color: lightgray;
  color: dimgray;
}

.others {
  background-image: linear-gradient(
    to right,
    red,
    orange,
    yellow,
    green,
    blue,
    indigo,
    violet
  );
  color: white;
}

.languageChanger {
  max-width: 70px;
  text-align: center;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: $qblue;
  font-family: "Bahij TheSansArabic Plain";
}

.qu-header-right {
  gap: 5px;
}

.user-menu {
  margin-left: 0px;
  gap: 5px;
}

.user-name {
  margin-right: 0px;
}

.force-ltr {
  direction: ltr;
}

// .q-table_menu_rtl {
//   position: absolute;
//   top: 50%;
//   left: 10px;
//   margin-top: -12px;
// }

// .q-table_menu_rtl.ant-dropdown-trigger {
//   box-sizing: border-box;
//   width: 12px;
//   height: 28px;
//   cursor: pointer;
//   fill: #B8BCC2;
//   transition: all 0.3s;
//   padding: 4px;
// }

.flex-container-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.flex-container-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 20px;
}

.flex-container-row img {
  height: 78px;
  width: 78px;
  min-width: 78px;
  min-height: 78px;
  object-fit: contain;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
}

.flex-container-row .icon {
  font-size: 38px;
  padding: 20px;
  height: 78px;
  background: #f4eff1;
  width: 78px;
  min-width: 78px;
  min-height: 78px;
  object-fit: contain;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
}

.flex-grow {
  flex-grow: 1;
}

.border-bottom {
  border-bottom: 1px dashed #dbdbdb;
}

.hr-line:not(:last-child) {
  border-bottom: 1px solid rgba(30, 37, 43, 0.15) !important;
  margin: 10px 0;
}

.file-name {
  color: #1379ac;
}

.file-remove-action {
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  color: #999999;
  letter-spacing: normal;
  cursor: pointer;
}

.qu-uploader + .ant-upload-list.ant-upload-list-picture {
  margin-top: 10px;
  margin-bottom: 15px;
}

.ant-notification {
  direction: ltr !important;
}

#arabic-loader-container {
  min-height: 100vh;
  min-width: 100%;
  background-color: white;
  position: absolute;
  z-index: 2000;
  left: 0;
  top: 0;
}

.partner-container-content .second_content .PPField:nth-child(1),
.partner-container-content .second_content .PPField:nth-child(2),
.partner-container-content .second_content .PPField:nth-child(4),
.partner-container-content .second_content .PPField:nth-child(6),
.partner-container-content .second_content .PPField:nth-child(8),
.partner-container-content .second_content .PPField:nth-child(9),
.partner-container-content .second_content .PPField:nth-child(10),
.partner-container-content .second_content .PPField:nth-child(11),
.partner-container-content .second_content .PPField:nth-child(12),
.partner-container-content .second_content .PPField:nth-child(13) {
  border: none;
}

.partner-container-content .second_content .PPField:nth-child(2),
.partner-container-content .second_content .PPField:nth-child(7),
.partner-container-content .second_content .PPField:nth-child(8),
.partner-container-content .second_content .PPField:nth-child(9),
.partner-container-content .second_content .PPField:nth-child(10),
.partner-container-content .second_content .PPField:nth-child(11),
.partner-container-content .second_content .PPField:nth-child(12),
.partner-container-content .second_content .PPField:nth-child(13) {
  height: 65px;
}

.partner-container-content .second_content .PPField:nth-child(8) {
  margin-top: 15px;
}

.partner-container-content .second_content .PPField:nth-child(6) {
  height: 85px;
}

@media only screen and (max-width: 600px) {
  .partner-container-content .second_content .PPField:nth-child(2),
  .partner-container-content .second_content .PPField:nth-child(7),
  .partner-container-content .second_content .PPField:nth-child(8),
  .partner-container-content .second_content .PPField:nth-child(9),
  .partner-container-content .second_content .PPField:nth-child(10),
  .partner-container-content .second_content .PPField:nth-child(11),
  .partner-container-content .second_content .PPField:nth-child(12),
  .partner-container-content .second_content .PPField:nth-child(13) {
    height: 90px;
  }
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #173994;
  border-radius: 50%;
  width: 6em;
  height: 6em;
  animation: spin 1s linear infinite;
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.w-100 {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.workflow-uploads {
  border: 1px solid #e6e6e6;
  padding: 0px 5px;
  word-break: break-all;
}

.qu-alert {
  &.centered {
    .ant-alert {
      text-align: center;
    }
  }

  &.small {
    .ant-alert {
      font-weight: 400;
      font-size: 13px;
      line-height: 1.2307;
    }
  }

  &.transparent {
    .ant-alert-info {
      color: rgba($black, 0.6);
      border-color: transparent;
      background-color: transparent;
    }
  }

  .ant-alert {
    font-weight: 500;
    font-size: 16px;
    border-radius: 10px;

    &-info {
      color: $alertInfoColor;
      background-color: $alertInfoBg;
      border-color: $alertInfoBorder;
    }

    &-warning {
      color: $alertWarningColor;
      background-color: $alertWarningBg;
      border-color: $alertWarningBorder;
    }

    &-success {
      color: $alertSuccessColor;
      background-color: $alertSuccessBg;
      border-color: $alertSuccessBorder;
    }

    &-error {
      color: $alertErrorColor;
      background-color: $alertErrorBg;
      border-color: $alertErrorBorder;
    }
  }

  .ant-alert-message {
    color: inherit;
  }
}

.redirect-button {
  display: block !important;
  margin-left: 0;
  margin-right: auto;
  margin-top: -10px;
}

.payment-method-checkbox {
  margin-bottom: 10px !important;
}

.label-required::after {
  content: "*️";
  color: red;
  font-size: medium;
}

@media only screen and (min-width: 666px) {
  .partner-layout {
    width: calc(100% - 220px) !important;
  }
}
.d-none {
  display: none !important;
}

.w-100-percent {
  width: 100% !important;
}

.ant-descriptions-item-label,
.data-view-value,
.data-view-label,
.ant-descriptions-item-content {
  word-break: break-word;
}

.preserve-whitespace {
  white-space: pre;
}

.force-show {
  display: block !important;
}

.mx-auto {
  display: block;
  margin: 0px auto;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.accept-v2 {
  background-color: #8ecb9f;
  flex-grow: 1;
  color: white;
  border-radius: 10px !important;
}

.reject-v2 {
  background-color: #ef471f;
  flex-grow: 1;
  color: white;
  border-radius: 10px !important;
}

.rejection-reason {
  background: #ef471f;
  color: white;
  padding: 0px 15px;
  border-radius: 5px;
}

// ************************* services v2 ************************************
.services-v2 {
  .ant-modal {
    max-width: unset !important;
    margin: unset !important;
    top: 0px;
  }

  .table-preview-img {
    object-fit: contain;
  }
  .flex-container-row-space-between {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flex-container-column-centered {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      outline: 1px solid #f6f6f6;
      width: 250px;
      height: 150px;
      object-fit: contain;
    }
  }
  .flex-container-row-flex-start {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    .age-card {
      min-height: 190px;

      .form-card__image {
        margin-bottom: 20px;
        margin-top: 10px;

        img {
          height: 50px;
          object-fit: cover;
        }
      }

      .form-card__label {
        text-align: center !important;
      }

      &.in-store-icon .form-card__image img {
        height: 108px;
        margin-top: 10px;
      }
    }
  }
  .w-200 {
    width: 200px;
  }
  .h-50 {
    height: 50px;
  }
  .h-50.in-store-img {
      height: 100px !important;
      min-height: 100px !important;
      max-height: 100px !important;
  }
}

span.inlineTextIcon {
  font-size: 25px;
  margin-top: -2px;
  font-weight: 500;
}

/* clients list page */
.qt-filter.clients-list-filter {
  .ant-select {
    width: 90% !important;
  }

  .anticon svg {
    color: #bfbfbf;
  }
}

// dashboard v2
.dashboard-v2 {
  padding-bottom: 50px;

  .bluebg {
    background-color: #233a90 !important;
    color: white;
  }

  .orangebg {
    background-color: #d9573b !important;
    color: white;
  }

  .chart-container {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    // min-height: 200px;

    .metric-pie {
      text {
        display: none;
      }

      .recharts-responsive-container {
        min-width: 100% !important;
      }
    }

    .title {
      font-size: medium;
      font-weight: bold;
    }

    .info {
      font-size: small;
    }

    .subtitle {
      font-size: x-small;
    }

    .subsubtitle {
      font-size: xx-small;
    }

    .amount {
      font-size: xx-large;
      font-weight: bold;
      margin-top: 24px;
    }

    .trend {
      margin-top: 24px;
    }

    .chart {
      display: flex;
      align-items: flex-end;
      justify-content: end;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .chart-rtl {
      display: flex;
      align-items: flex-end;
      justify-content: end;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.fullscreen-modal {
}

.partner-main {
  background-color: #f8f8f8;
}

.v3-modal {
  .ant-modal {
    max-width: unset !important;
    margin: unset !important;
    top: 0px;
  }
}

.primary-text {
  color: #d15e28;
}

.secondary-text {
  color: #173994;
}

.html-output-div {
  background-color: #f6f6f6;
  height: 200px !important;
  overflow: auto;
  height: auto;
  position: relative;
}

.v3-edit-container {
  .qu-uploader .single-image_mini-container {
    height: auto;
    max-height: 100px !important;
    min-height: 50px !important;
  }
  .qu-uploader .single-image_mini-container .details_wrapper .title {
    margin: 0;
    max-width: 100px;
    font-size: 13px;
    line-height: 26px;
  }
  .qu-uploader .single-image_mini-container .details_wrapper {
    padding-left: 15px;
    justify-content: center;
    min-width: 100px;
  }
  .qu-uploader .single-image_mini-container .actions {
    display: flex;
    justify-content: unset;
    gap: 10px;
  }
  .qu-uploader .single-image_mini-container .image {
    position: initial;
    min-height: 51px !important;
    height: 100%;
    width: auto;
    max-width: 200px !important;
    object-position: center center;
    object-fit: contain !important;
    border-radius: 5px;
  }
  .qu-uploader .upload-button {
    font-size: 13px;
    /* align-items: flex-end; */
    // width: 340px;
    // height: 50px;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
    text-decoration: underline;
    color: black;
  }
  .qu-button:hover {
    color: #333 !important;
    border: 0px !important;
    // border:1px solid black !important;
  }
}
