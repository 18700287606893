.surveys {
  .spinner-component {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 240px);
  }
}

.timeline_images {
  list-style-type: none;

  display: flex;
  justify-content: space-between;
}

.gallery_spinner {
  display: flex;
  vertical-align: center;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 240px);
}

.gallery-modal {
  .ant-modal-body {
    position: relative;
    min-height: 200px;

    .download {
      position: absolute;
      top: 30px;
      right: 30px;
    }

    .preview-img {
      width: 100%;
    }
  }

  .ant-modal-footer {
    padding: 16px 24px;

    .description {
      text-align: left;
    }

    .date {
      margin: 0;
    }
  }

  .arrows {
    position: fixed;
    left: 0;
    top: 50%;
    padding: 0;
    width: 100%;
    color: $white;
    transform: translateY(-50%);

    .arrow {
      position: absolute;
      width: 34px;
      background: rgba(0, 0, 0, 0.45);
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }

    &-left {
      left: 0;

      svg {
        transform: rotate(180deg);
      }
    }

    &-right {
      right: 0;
    }
  }
}

.qt.client-campaigns .q-table .ant-table .cursorPointer {
  cursor: pointer;
}

.timeline_photo {
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  margin: 6px 6px 0 0;
  border: 2px solid $white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  max-height: 70px;
  width: calc(50% - 6px);
  overflow: hidden;
  cursor: pointer;

  img {
    min-height: 68px;
    width: auto;
    min-width: 96px;
    object-fit: cover;
  }
}

.gallery-modal {

  .qu-preview-head,
  .description {
    word-break: break-word;
  }
}

@media only screen and (min-width: 1024px) {
  .gallery-modal {
    .arrows {
      width: 700px;
      left: 50%;
      transform: translate(-50%, -50%);

      .arrow {
        background: transparent;
        width: auto;

        &:hover {
          color: $mid-gray;
        }
      }

      &-left {
        margin-left: -100px;
      }

      &-right {
        margin-right: -100px;
      }
    }
  }
}

.reports_listing {
  line-height: 1;
  margin: 15px 0 15px 22px;
  cursor: pointer;

  div {
    float: right;
    margin-right: 15px;

    a {
      fill: #5b6475;
      opacity: 0.6;
    }
  }

  .check_report_icon {
    margin-right: 10px;
    cursor: pointer;
  }

  .open_report_icon {
    margin-left: 10px;
    margin-right: 10px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    color: #000;
  }

  .download_report_icon {
    margin-right: 10px;
    margin-left: 10px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    color: #000;
  }

  .delete_report_icon {
    width: 14px;
    height: 14px;
    cursor: pointer;
  }

  .date_span {
    font-size: 12px;
    margin-left: 27px;
  }
}

.uploadReport>div {
  display: block !important;
  padding-bottom: 20px;
}

.light-grey-disabled {
  .ant-dropdown-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.generate_report_print_area {
  background: #f4f4f4;
  width: 1200px !important;
}