.client {
  position: relative;

  &-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-content {
    max-width: 1000px;
    width: 100%;
  }

  &-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 44px);
    width: 100%;
    overflow: auto;
  }
}
