.auth {
  min-height: 100vh;

  &-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 100vh;
    width: 100%;
    min-height: 100vh;
    padding: 0 20px;
    background-color: $white;
    overflow: auto;

    &-header {
      display: flex;
      padding: 20px 0;
      margin-bottom: 20px;

      svg {
        height: 36px;
      }
    }

    &-footer {
      text-align: center;
      padding-top: 40px;
      padding-bottom: 69px;
      font-size: 15px;
      line-height: 19px;

      p {
        margin: 0;
      }
    }
  }

  &-form {
    align-self: center;
    max-width: 340px;
    width: 100%;

    h1 {
      margin-bottom: 38px;
    }

    .account-selector {
      display: flex;
      width: 100%;

      &-help {
        margin-bottom: 20px;
        margin-top: 5px;
        color: $text-gray-light;
      }

      .qu-radio-button {
        flex: 1;
        text-align: center;
      }
    }
  }

  .remember {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 25px;
  }

  &-terms {
    margin-top: 5px;
    margin-bottom: 25px;
    color: $text-gray-light;
    font-size: 15px;
    line-height: 19px;
  }

  &-captcha {
    margin-top: 5px;
    margin-bottom: 25px;
  }

  @media only screen and (max-width: 480px) {
    &-section {
      &-header {
        padding-top: 40px;
        margin-bottom: 10px;
        justify-content: center;
      }

      &-footer {
        padding-top: 55px;
        padding-bottom: 38px;
      }
    }

    &-form {
      h1 {
        text-align: center;
        font-size: 25px;
        line-height: 30px;
      }
    }

    &-terms {
      width: 275px;
      font-size: 13px;
      line-height: 18px;
    }
  }
}

.qu-sidebar-logo svg {
  display: none;
}

.qu-sidebar-logo {
  height: 60px;
  background-repeat: no-repeat;
  // background-image: url("https://quantum-files-bucket.s3.eu-central-1.amazonaws.com/client_profiles/149/1646219954135-223961847_1020217682122570_2017453311760278077_n_%281%29.jpeg");
  // background-image: url("https://i.ibb.co/bmhVnYh/Alothim-removebg-preview.png");
  background-image: url('https://i.ibb.co/ScGm4LF/output-onlinepngtools.png');
  background-size: contain;
  background-position: center;
}
