.cancel-subscription-modal {
    .ant-modal-header {
        margin-right: 25px;
        padding-top: 20px;
    }

    .ant-modal-body {
        margin-right: 25px;
        padding-top: 20px;
    }

    .ant-modal-footer {
        .ant-btn-primary {
            margin-right: 5px;
        }
    }
}

@media only screen and (max-width: 575px) {
    .cancel-subscription-button-en {
        margin-top: 10px;
        text-align: left;
    }

    .cancel-subscription-button-ar {
        margin-top: 10px;
        text-align: right;
    }
}