body {
    overflow: hidden !important;
}

.user-menu.ant-dropdown-trigger{
    margin-inline-start: 30px;
}

.user-menu-wrapper {
    margin-inline-start: 25px;
}

.user-menu-wrapper .user-name{
    height: 40px !important;
}

.qu-header-partner .welcome.profileName{
    font-size: 13px;
    font-weight: 600;
    display: inline-block;
}

.qu-header-partner .companyName{
    font-size: 11px;
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
}

.qu-header-partner .companyName:hover{
    text-decoration: none !important;
}

.notifications-menu-popover.partner .notifications-menu-icon {
    height: 18px;
}

.qu-sidebar .sidebar-menu .bottomItem{
    bottom: 30px;
    position: absolute;
}

.notifications-menu-popover.partner .notifications-menu-icon svg {
    fill: #828282 !important;
}

.notifications-menu-popover.ant-popover-open .notifications-menu-icon svg {
    fill: #173994 !important;
}

.notifications-menu {
    background-color: #00000014 !important;
    width: 40px !important;
    height: 40px !important;
    margin-inline-start: 15px;
}

.qu-sidebar .ant-menu.sidebar-menu li{
    padding: 15px 0 10px 5px!important;
}

.ant-menu-item.active .anticon::before {
    content: '';
    position: absolute;
    width: 26px;
    height: 27px;
    display: block;
    background-color: #1739942b;
    border-radius: 5px;
    top: 13px;
    left: 0px;
}

.qu-sidebar .sidebar-menu .ant-menu-sub .ant-menu-item {
    line-height: 1.6;
    font-size: 15px;
    padding: 0 !important;
}

.ant-menu-item.active::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 26px;
    display: block;
    background-color: #173994;
    border-radius: 5px;
    right: -15px;
    top: 13px;
}

.qu-sidebar .sidebar-menu .bottomItem::before {
    left: 206px;
}

.ant-menu-sub .ant-menu-item.active::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 24px;
    display: block;
    background-color: #173994;
    border-radius: 5px;
    right: -14px;
    top: 0px;
}

.ant-menu-submenu {
    &-title .submenu-icon {
        float: inline-end;
    }
}

//Settings
.settings-menu {
    border-right: none !important;
    background-color: white !important;
}

.notifications-menu-wrapper.settings{
    display:none;
}

.settings-card .InfoCard {
    min-height: 330px;
    background-color: white;
}

.settings-card .InfoCard__body {
    background-color: white;
}

.settings-card .InfoCard__body .ant-menu-item {
    height: 30px !important;
}

.settings-card .InfoCard__header{
    background-color: white !important;
}