.publicProfile {
  .qu-uploader + .ant-upload-list.ant-upload-list-picture .ant-upload-list-item-thumbnail img {
    object-fit: fill;
  }

  .ProfileCard__previewLabel {
    z-index: 999;
  }

  .input {
    position: relative;

    .closeIcon {
      position: absolute;
      right: 0;
      top: 20px;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
      background: white;
    }
  }

  .ProfileCard__serviceLabel {
    font-weight: 700;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    border-radius: 2px;
  }

  .PreviewIcon {
    svg {
      position: relative;
      border: none;
      background: none;
      width: 17px;
    }
    path {
      fill: #d1602b;
    }
  }

  .public-profile-footer {
    margin-top: 20px;
    margin-bottom: 30px;

    .preview {
      display: none;
    }
  }

  @media only screen and (max-width: 666px) {
    .public-profile-title {
      margin-top: 25px;

      h1 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 5px;
      }

      h5 {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .profile-card-preview {
      display: none;
    }

    .public-profile-footer {
      margin-top: 5px;

      .qu-button {
        width: 100%;
      }

      .preview {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}
