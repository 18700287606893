.brief {
  &-step {
    padding: 40px 0;
    margin: 0 16px;
    height: 100%;

    .age-card {
      padding: 20px 14px 16px;

      span {
        img {
          height: 65px !important;
        }
      }
    }

    .consumer_research_survey {
      padding: 25px 25px 16px;
    }

    .consumer_research_challenge {
      padding: 25px 18px 16px;
    }

    .consumer_research_respondents {
      .ant-input-number {
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 #0000000a;
      }
    }

    &-header {
      text-align: center;
      margin-bottom: 40px;

      p {
        font-size: 16px;
        color: $text-gray;
      }
    }

    &-0 {
      max-width: 1090px;

      .card {
        &--selectable {
          margin-bottom: 16px;
        }

        &__image {
          object-fit: cover;
        }
      }
    }

    &-success {
      height: calc(100vh - 44px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      svg {
        width: 96px;
        color: $light-green;
        margin-bottom: 23px;
      }

      h1 {
        margin-bottom: 16px;
        line-height: 33px;
      }

      p {
        max-width: 532px;
        color: rgba($text-black, 0.6);
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 32px;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    &-step {
      padding: 40px 0 20px;

      &-0 {
        .card {
          &__head {
            padding-top: 128px;
          }
        }
      }

      &-success {
        height: calc(100vh - 156px);

        p {
          max-width: 308px;
        }
      }
    }
  }
}

.qu-channels {
  &-content {
    display: flex;
    flex-direction: column;
    padding: 25px 30px;
    width: 100%;

    .qu-brief-tags {
      margin-bottom: 25px;
      overflow: auto;
    }
  }

  &-header {
    margin-bottom: 30px;

    h1 {
      display: flex;
      margin-bottom: 5px;
      line-height: 32px;
      justify-content: space-between;
    }

    p {
      color: $text-gray;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 19px;
    }
  }

  &-filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: -10px;

    span {
      color: $gray;
      margin-right: 10px;
    }
  }

  &-grid {
    .ProfileCard {
      margin-bottom: 16px;
    }
  }

  @media only screen and (max-width: 666px) {
    &-header {
      margin-bottom: 25px;
    }

    &-filter {
      display: none;
    }

    &-content {
      padding: 25px 20px;

      .qu-brief-tags {
        min-height: 32px;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        margin-bottom: 0;
        order: 2;
      }

      .qu-brief-filter-modal {
        margin-bottom: 20px;
      }
    }

    &-grid {
      margin-top: 20px;
      order: 3;
    }
  }
}

.brief-payment-files-group-title {
  color: $blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  margin: 5px 0;
}

.brief-payment-inline-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.qu-brief {
  &-wide {
    width: calc(100vw - 260px);
    margin: 0 0 0 -90px;
  }

  &-main {
    .online_payment_sub_label {
      padding-left: 35px;
      color: darkgrey;
      font-size: 12px;
      font-style: italic;
    }
  }

  &-view {
    .status-label.partially {

      &.pending,
      &.approved,
      &.rejected {
        font-size: 8px;
      }

      &.account_approval {
        font-size: 7px;
      }
    }

    .edit-campaign-link {
      color: $orange;
    }

    .edit-campaign-link.ant-tooltip-disabled-compatible-wrapper {
      color: rgba(0, 0, 0, 0.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .online_payment_sub_label {
      padding-left: 35px;
      color: darkgrey;
      font-size: 12px;
      font-style: italic;
    }
  }

  @media only screen and (max-width: 666px) {
    &-view {
      .qu-alert {
        margin: 0 15px;
      }
    }

    &-main {
      max-width: calc(100vh - 65px);
      min-height: calc(100vh - 65px);
    }
  }
}

.coupon-container {
  margin-bottom: 12px;
}

.services-form-coupon {
  .qu-button {
    margin-top: 5px;
  }
}

.testahel-summary {
  .BS__header {
    background-color: #6a16e2;

    h3 {
      color: #fff;
    }

    .BS__subtitle {
      color: #fff !important;
    }
  }
}

.assignBrief {
  font-size: 15px;
  font-weight: 500;
}

.ad-estimates-main {
  text-align: center !important;
  padding: 15px !important;
  margin-bottom: 20px !important;

  .ad-estimates-title {
    color: initial !important;
    font-weight: 500;
    margin-bottom: unset;
  }

  .ad-estimates-body {
    text-align: left;
  }

  .ad-estimates-hr {
    margin: 10px 20px 10px 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  .ad-estimates-text-left {
    text-align: left;
  }

  .ad-estimates-text-right {
    text-align: right;
    color: #1e252b;
    font-weight: 600;
    line-height: 20px;
  }
}

.arabicLanguage {
  unicode-bidi: bidi-override;
  direction: RTL;
}

.additional_charges .ant-col {
  font-size: 12px !important;
}

.customized-box-cart-summary-title {
  margin-top: 15px;
  color: #1379ac;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 5px;
}

.customized-box-cart-summary-text {
  font-size: 14px;
  line-height: 1.2;
}

.campaign_additional_charges {
  .qu-button-link {
    display: none;
  }
}

.potential-audience-main {
  background: rgb(38, 121, 171) !important;
  color: white !important;
  text-align: center !important;
  padding: 15px !important;
  margin-bottom: 20px !important;

  .potential-audience-title {
    color: white !important;
    font-weight: 500;
    margin-bottom: unset;
  }

  .potential-audience-count {
    color: white !important;
    font-weight: bolder;
    margin-bottom: 5px;
  }

  .potential-audience-hr {
    margin-bottom: 10px;
    border-top: 1px solid #ffffff1a;
  }

  .potential-audience-text {
    margin-bottom: unset;
  }
}

.testahel-services-form-item {
  align-items: baseline !important;
}

.testahel-qu-modal-details {
  .ant-modal {
    width: 1100px !important;
  }

  .ant-modal-body {
    max-width: 1500px !important;
  }
}

.qu-testahel-brief-content {
  .card--selectable {
    min-height: 430px !important;
  }
}

.overlay-container {
  background: #d7eaf5;
  position: absolute;
  opacity: 0.9;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay-content {
  background: transparent;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 13;
  display: flex;
  justify-content: center;
  align-items: center;
}

.position_relative {
  position: relative;
}

.no-padding {
  padding: 0px;
}

.display-none {
  display: none;
}

.height-auto {
  min-height: initial;
  max-height: initial;
}

.image-within-available-services-table {
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center top;
  border-radius: 10px;
  margin: 15px 10px 10px 0px;
}

.box-center {
  display: flex;
  align-items: center;
  gap: 10px;
}

.content-media-popup-container {
  width: 100%;
  padding: 20px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-margin {
  margin: 0px;
}

.mb-35 {
  margin-bottom: 35px;
}

.pills-container {
  box-sizing: border-box;
}

.pill {
  background: lightgray;
  color: #333;
  border-radius: 20px;
  margin-right: 10px;
  /* margin-bottom: 20px; */
  padding: 5px 10px;
  // line-height: 40px;
  /* white-space: pre-wrap; */
  word-break: break-all;
  display: inline-block;
}

.social-list {
  margin: 10px 0px 0px -6px;
  margin-bottom: 0px !important;
}

.qu-brief-header {
  .header-content {
    line-height: 25px !important;
  }

  .header-stepper {
    margin-left: unset !important;

    .qu-stepper .steps__item {
      line-height: 25px !important;
      min-width: 110px;
    }

    .qu-stepper {
      @media only screen and (max-width: 1250px) {
        width: 100%;

        .steps {

          &__item {
            flex: 1;
            min-width: auto;
            height: 10px;
            border-radius: 0;
            border-top: 0;
            border-bottom: 0;
            font-size: 0;
            line-height: 0;
            background-color: #DDE7ED;
            border-color: white;

            &--passed {
              border-color: white;
              background-color: $blue;
            }

            &--current {
              background: #00a8ff;
            }

            &--disabled {
              background-color: $text-gray-light;

              svg {
                display: none;
              }
            }
          }

          &__divider {
            display: none;
          }
        }
      }
    }
  }
}

.campaign-products-list thead {
  display: none !important;
}