@font-face {
  font-family: HKGrotesk;
  src: url('../../node_modules/quantum_components/build/assets/fonts/HKGrotesk-Bold.woff') format('woff2'),
    url('../../node_modules/quantum_components/build/assets/fonts/HKGrotesk-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: HKGrotesk;
  src: url('../../node_modules/quantum_components/build/assets/fonts/HKGrotesk-Light.woff2') format('woff2'),
    url('../../node_modules/quantum_components/build/assets/fonts/HKGrotesk-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: HKGrotesk;
  src: url('../../node_modules/quantum_components/build/assets/fonts/HKGrotesk-Medium.woff2') format('woff2'),
    url('../../node_modules/quantum_components/build/assets/fonts/HKGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: HKGrotesk;
  src: url('../../node_modules/quantum_components/build/assets/fonts/HKGrotesk-Regular.woff2') format('woff2'),
    url('../../node_modules/quantum_components/build/assets/fonts/HKGrotesk-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Bahij TheSansArabic';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-Black.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bahij TheSansArabic Plain';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-Plain.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bahij TheSansArabic SemiLight';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-SemiLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bahij TheSansArabic';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bahij TheSansArabic';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bahij TheSansArabic';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-ExtraBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bahij TheSansArabic';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-SemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bahij TheSansArabic';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bahij TheSansArabic SemiLight';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-SemiLight_1.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bahij TheSansArabic';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-ExtraBold_1.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bahij TheSansArabic';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-SemiBold_1.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bahij TheSansArabic Plain';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-Plain_1.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bahij TheSansArabic';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-Light_1.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bahij TheSansArabic';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-Black_1.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bahij TheSansArabic';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-ExtraLight_1.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bahij TheSansArabic';
  src: url('../assets/fonts/Bahij_TheSansArabic/BahijTheSansArabic-Bold_1.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
