.brief-view {
  &-mobile {
    &-media {
      margin: 0;
      align-items: center;
      justify-content: start;

      .BSUnit__bList-item {
        align-items: start;
        display: flex;
        flex-direction: column;
        text-align: start;
        margin: 0;
      }

      .BSUnit__bList-item {
        margin: 10px 10px 5px 0;
      }
    }
  }
}

.SS {
  background-color: #ffffff;
}

.qu-radio-blue {
  background: #FFFFFF !important;
  border-color: #1379AC !important;
  color: #0e6088 !important;

  &:hover {
    color: #ADD8E6 !important;
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #1379AC !important;
    border-color: #1379AC !important;
    color: #FFFFFF !important;

    &::before {
      background-color: #d9d9d9 !important;
    }

    &:hover {
      color: #ADD8E6 !important;

      &::before {
        background-color: #d9d9d9 !important;
      }
    }
  }

  .ant-radio-button+span {
    display: flex;
    align-items: center;
  }
}

.action-needed-alerts {
  .ant-alert-with-description {
    padding: 5px;
  }
}