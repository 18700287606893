.text-center {
  text-align: center;
}

.negative-card-body {
  margin: 0 -20px;
}

.child-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: $light-blue;
  text-decoration: underline;
  cursor: pointer;

  svg {
    margin-right: 5px;
  }

  .hidden {
    position: absolute;
  }

  &:hover {
    text-decoration: none;
  }

  &.disabled {
    pointer-events: none;
    color: $mid-gray;
  }
}
