.report-page-container {
  color: white;
  align-items: stretch;

  &.flex-column,
  .flex-column {
    display: flex;
    flex-direction: column;
  }

  &.report-page1,
  .report-page1 {
    background-image: url('../../../../assets/ReportImages/1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    top: 0;
    height: 1712px;

    .main-first-div {
      position: absolute;
      top: 50%;
      width: 100%;
    }

    .first-page-small-heading {
      color: #e94b2e;
      font-size: 40px;
      line-height: 1;
      padding-left: 50px;
    }

    .first-page-heading {
      color: #173994;
      font-size: 80px;
      line-height: 1.3;
      padding-left: 50px;
    }

    .fourth-page-heading {
      color: #173994;
      font-size: 120px;
      text-align: center;
    }

    .first-page-date {
      color: #173994;
      font-size: 30px;
      text-align: center;
      line-height: 1.4;
      padding-left: 50px;
      font-weight: unset;
    }

    .first-page-company-logo {
      position: absolute;
      bottom: 40px;
      left: 40px;
      width: 160px;
    }

    .first-page-client-logo {
      position: absolute;
      bottom: 40px;
      left: 200px;
      width: 160px;
    }

    .first-page-logo-image {
      position: absolute;
      bottom: 40px;
      right: 50px;
      width: 200px;
    }
  }

  &.report-page2,
  .report-page2 {
    background-image: url('../../../../assets/ReportImages/2.png');
    background: #f4f4f4;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    top: 0;
    height: 1726px;

    .second-page-green-strip-image {
      position: absolute;
      top: 0;
      left: 0%;
      width: 500px;
      height: 120px;
    }

    .second-page-heading {
      color: white;
      position: absolute;
      top: 25px;
      left: 45px;
      font-size: 45px;
    }

    .second-page-logo-image {
      position: absolute;
      top: 1.5%;
      right: 3%;
      width: 180px;
    }

    .second-page-brief-details {
      color: black;
      position: relative;
      top: 10%;

      h2 {
        color: black;
        font-size: 45px;
        line-height: 1;
        text-align: center;
        text-transform: capitalize;
      }

      h3 {
        background: #f4f4f4;
        color: #173994;
        font-size: 40px;
        text-align: center;
        line-height: 3;
        text-transform: capitalize;
      }

      .chart-left-labels {

        h2 {
          font-size: 40px;
          text-align: start;
          padding-left: 30%;
          text-transform: capitalize;
        }

        h3 {
          font-size: 35px;
          text-align: start;
          padding-left: 30%;
          text-transform: capitalize;
        }
      }
    }

    .pie-chart {
      position: relative;

      text {
        text-transform: capitalize;
      }
    }
  }

  &.report-page3,
  .report-page3 {
    background-image: url('../../../../assets/ReportImages/2.png');
    background: #f4f4f4;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    top: 0;
    height: 1714px;

    .second-page-green-strip-image {
      position: absolute;
      top: 0;
      left: 0%;
      width: 500px;
      height: 120px;
    }

    .second-page-heading {
      color: white;
      position: absolute;
      top: 25px;
      left: 45px;
      font-size: 45px;
    }

    .second-page-logo-image {
      position: absolute;
      top: 1.5%;
      right: 3%;
      width: 180px;
    }

    .second-page-brief-details {
      color: black;
      position: relative;
      top: 10%;

      h2 {
        color: black;
        font-size: 45px;
        line-height: 1;
        text-align: center;
        text-transform: capitalize;
      }

      h3 {
        background: #f4f4f4;
        color: #173994;
        font-size: 40px;
        // text-align: center;
        line-height: 3;
        text-transform: capitalize;
        padding-left: 20px;
      }

      .chart-left-labels {
        ul {
          list-style: none;
          padding-left: 10%;
        }

        .square-bullet {
          font-size: xxx-large;
          padding-right: 10px;
        }

        h2 {
          font-size: 32px;
          text-align: start;
          padding-left: 40px;
          padding-bottom: 15px;
          text-transform: capitalize;
        }

        h3 {
          font-size: 27px;
          text-align: start;
          padding-left: unset;
          line-height: unset;
          text-transform: capitalize;
        }
      }
    }

    .pie-chart {
      position: relative;

      text {
        text-transform: capitalize;
      }
    }
  }

  &.report-page4,
  .report-page4 {
    background-image: url('../../../../assets/ReportImages/1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    top: 0;
    height: 1714px;

    .main-first-div {
      position: absolute;
      top: 40%;
      width: 100%;
    }

    .first-page-small-heading {
      color: #e94b2e;
      font-size: 40px;
      line-height: 1;
      padding-left: 50px;
    }

    .first-page-heading {
      color: #173994;
      font-size: 80px;
      line-height: 1.3;
      padding-left: 50px;
    }

    .fourth-page-heading {
      color: #173994;
      font-size: 200px;
      font-weight: 500;
      text-align: center;
    }

    .first-page-date {
      color: #173994;
      font-size: 30px;
      text-align: center;
      line-height: 1;
      padding-left: 50px;
      font-weight: unset;
    }

    .first-page-company-logo {
      position: absolute;
      bottom: 40px;
      left: 40px;
      width: 160px;
    }

    .first-page-client-logo {
      position: absolute;
      bottom: 40px;
      left: 200px;
      width: 160px;
    }

    .first-page-logo-image {
      position: absolute;
      bottom: 40px;
      right: 50px;
      width: 200px;
    }
  }
}