.qu-collapse {
  background-color: $white;
  border-radius: 8px;

  .ant-collapse {
    &-item + .ant-collapse-item {
      border-top: 1px solid $border-default;
    }

    &-header {
      font-size: 22px;
      font-weight: 500;
      color: $blueActive !important;

      &:hover,
      &:focus {
        color: $blue !important;
      }
    }

    &-arrow {
      transform: translateY(-50%) scale(1.6, 1.4) !important;
    }
  }

  .qu-switch-control {
    .ant-form-item-label {
      order: 1;
      margin-top: 5px;

      label {
        cursor: pointer;
        font-size: 16px;
      }
    }
  }
}
