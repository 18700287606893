//Buttons
.qu-button {
	background-color: #EB4B2D !important;
    border-radius: 12px !important;
    text-transform: capitalize !important;

    &-green {
		background-color: #2BC155 !important;
    }

    &-soft {
		color: white !important;
    }

    &-outline {
		background-color: white !important;
    }

    &-outline-green {
		background-color: white !important;
		border: 1px solid #2BC155 !important;
		color: #2BC155 !important;
	    border-radius: 12px !important;
	    font-size: 15px !important;
	    padding: 4px 12px !important;
    }

    &-outline-red {
		background-color: white !important;
		border: 1px solid #EB4B2D !important;
		color: #EB4B2D !important;
	    border-radius: 12px !important;
	    font-size: 15px !important;
	    padding: 4px 12px !important;
    }
}

.qu-button:hover {
	color: #EB4B2D !important;
	background-color: white !important;
	border: 1px solid #EB4B2D !important;
}

.qu-button-outline-green:hover {
	color: white !important;
	background-color:#2BC155 !important;
	border: 1px solid white !important;
}

.qu-button-outline-red:hover {
	color: white !important;
	background-color:#EB4B2D !important;
	border: 1px solid white !important;
}

.qu-button-green:hover {
	border: 1px solid #2BC155 !important;
	color: #2BC155 !important;
	background-color: white !important;
}

.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active{
	background: #f5f5f5 !important;
    border: 1px solid #d9d9d9 !important;
	color: rgba(0, 0, 0, 0.25) !important;
}

//Tables
.q-table .ant-table {
	box-shadow: none !important;
}


//Statuses
.status-label {
    border-radius: 8px !important;
    padding: 8px !important;
}

.status-label.approved {
    background-color: #0F39DD !important; //blue
}

.status-label.completed {
    background-color: #808080 !important; //grey
}

.status-label.ongoing {
    background-color: #2BC155 !important; //green
}

.status-label.pending {
    background-color: #EB4B2D !important; //red
    color: white !important;
}

//Tabs
.ant-radio-group-solid .qu-radio-button {
    background: transparent !important;
    border: none !important;
    color: #747474 !important;
    font-size: 12px !important;
    margin: 5px !important;
    height: 35px !important;
    line-height: 35px !important;
}

.ant-radio-group {
    background: #d1d1d170 !important;
    border-radius: 25px !important;
}

.ant-radio-group-solid .qu-radio-button.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
	border-color: transparent !important;
    border-radius: 20px !important;
    color: #747474 !important;
    font-size: 12px !important;
    background: white !important;
    border: 1px solid transparent !important;
}

.ant-radio-button-wrapper:not(:first-child)::before{
	display: none !important;
}

.ant-radio-group-solid .qu-radio-button:hover {
    color: black !important;
}

.ant-radio-group-solid .qu-radio-button.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	background: white !important;
    border: 1px solid #747474 !important;
}

//Dashboard
.pieChartLegend {
	display: block;
	font-size: 12px;
	width: 130px;

	.label {    
		width: 70px;
	    white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    display: inline-block;
	    height: 10px;
	    line-height: 10px;
	}

	.percentage {
      display: inline-block;
      float: inline-end;
    }
}

.ant-radio-group.system-filters {
	width: 288px; 
	float: inline-end;
}

.ant-radio-group.system-filters {
	width: 288px; 
	float: inline-end;
}

.ant-badge-dot {
	height: 10px !important;
	width: 10px !important;
}

@media (min-width: 960px) {
	.ant-radio-group.system-filters {
		margin-inline-end: 20px;
	}
}

@media (max-width: 1290px) {
	.ant-col-xl-12.countries-stats, .ant-col-xl-12.devices-stats {
	    display: block !important;
	    -webkit-box-flex: 0 !important;
	    -ms-flex: 0 0 100% !important;
	    flex: 0 0 100% !important;
	    max-width: 100% !important;
	}
}