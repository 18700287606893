.full-height-v3 {
  min-height: 100vh;
  padding-bottom: 10px;
}

.v3-section {
  .label-required{
    word-break: break-word;
  }
  // margin-bottom: 50px;

  #html_output {
    margin-top: 10px;
    max-width: 300px;
    max-height: 300px;
    overflow: auto;
  }

  .form-card__icon::before,
  .form-card__icon::after {
    display: none;
  }

  label {
    font-size: 15px;
    font-weight: 500;
    word-break: break-all;
  }

  .sku-container {
    display: flex;
    flex-direction: row;
  }

  .v3-targeting .form-card {
    width: 140px;

    .form-card__image {
      display: none;
    }

    .form-card__label{
        margin-top: 0px;
    }
  }

  .v3-services .form-card {
    width: 190px;
    min-height: 260px;

    img{
      height: 100%;
    }
  }

  .form-card__label,
  .form-card__sublabel {
    text-transform: capitalize;
    word-break: break-word;
    white-space: pre-wrap;
  }

  .form-card__label {
    font-size: 15px;
    font-weight: 500;
  }

  .form-card__sublabel {
    font-size: 14px;
    font-weight: 500;
  }

  .full-width {
    width: 100%;
  }
}



.tag-preview {
  font-size: 15px !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  background: rgba(209, 94, 40, 0.2) !important;
  color: #173994 !important;
  border-color: rgba(209, 94, 40, 0.2) !important;
}

.xs-btn{
  text-transform: capitalize;
  height: 27px;
  line-height: 29px;
}

.text-size-14{
  font-size: 14px !important;
}

.brief-v3-services-container.publisher-marketplace {
  .form-card{
      width: 200px;
      min-height: 200px;
      padding-top: 50px;

      .form-card__sublabel{
        width: 170px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 auto;
      }

      .form-card__image img{
        max-height: 100%;
      }
  }
}