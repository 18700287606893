.create-update-button {
    width: 100%;
}

#message-container {
    width: 100%;
    height: 500px;
    margin: 0 auto;
    font-size: 0;
    border-radius: 5px;
    overflow: hidden;
}

.main-chat-div {
    width: 100%;
    height: 500px;
    display: inline-block;
    font-size: 15px;
    vertical-align: top;
}

h2,
h3 {
    margin: 0;
}

.status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 7px;
}

.green {
    background-color: #58b666;
}

.orange {
    background-color: #ff725d;
}

.blue {
    background-color: #6fbced;
    margin-right: 0;
    margin-left: 7px;
}

#chat {
    // background: #eff3f7;
    padding-left: 0;
    margin: 0;
    list-style-type: none;
    overflow-y: scroll;
    height: 440px;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    position: relative;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    direction: initial !important;
}

#chat li {
    padding: 10px 0;
}

#chat h2,
#chat h3 {
    display: inline-block;
    font-size: 13px;
    font-weight: normal;
}

#chat h3 {
    color: #bbb;
}

#chat .entete {
    margin-bottom: 5px;
}

#chat .message {
    padding: 20px;
    color: #fff;
    line-height: 25px;
    max-width: 90%;
    display: inline-block;
    text-align: left;
    border-radius: 5px;
    white-space: pre-line;
}

#chat .me {
    text-align: right;
}

#chat .you .message {
    background-color: #58b666;
}

#chat .me .message {
    background-color: #6fbced;
}

#chat .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
}

#chat .you .triangle {
    border-color: transparent transparent #58b666 transparent;
    margin-left: 15px;
}

#chat .me .triangle {
    border-color: transparent transparent #6fbced transparent;
    margin-left: 440px;
}

.main-chat-div .chat-footer {
    height: 60px;
    padding: 15px 0 0 0;

    form {
        width: 100%;

        .ant-form-item {
            margin-bottom: unset;
        }
    }
}