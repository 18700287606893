@import '../../styles/variables.scss';

.spinner_overlay {
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -25px;
    text-align: center;

    &-icon {
      color: $primary;
      font-size: 48px;
    }

    &-text {
      color: $primary;
      font-size: 18px;
      margin-top: 10px;
      font-weight: bold;
    }
  }
}

.spinner-component {
  .spinner {
    &-icon {
      color: $primary;
      font-size: 48px;
    }

    &-text {
      color: $primary;
      font-size: 18px;
      margin-top: 10px;
      font-weight: bold;
    }
  }
}
