.html-rtl {
  direction: rtl !important;
  font-family: "Bahij TheSansArabic Plain";

  div{
    text-align: right;

    .targeting-key-input{
      padding-right: 30px !important;
    }
  }

  .details_wrapper{
    margin-right: 20px;
  }

  .services-item-qty {
    margin-right: 0px;
    margin-left: 15px;
  }

  .ant-picker-year-btn {
    margin-right: 5px;
  }

  .ant-modal-title {
    margin: 0;
    margin-right: 20px;
  }

  .qu-sidebar {
    padding: 30px 30px 30px 0 !important;
  }
  .ant-menu-item .anticon, .ant-menu-submenu-title .anticon{
    margin: 0 5px;
  }

  .ant-menu-item.active .anticon::before {
      content: '';
      position: absolute;
      width: 26px;
      height: 27px;
      display: block;
      background-color: #1739942b;
      border-radius: 5px;
      top: 12px;
      right: 0px;
  }

  .ant-menu-item.active::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 26px;
      display: block;
      background-color: #173994;
      border-radius: 5px;
      left: -15px;
      right: inherit;
      top: 12px;
  }

  .qu-sidebar .sidebar-menu .ant-menu {
    padding-left: 0px;
    padding-right: 10px;
    text-align: right;
  }

  .qu-sidebar .sidebar-menu {
    text-align: right;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    left: 0px;
    right: -1px;
  }

  //notifications menu
  .notifications-menu-popover .ant-badge-count {
    direction: ltr;
  }

  .notifications-menu-wrapper {
    .ant-popover-content {
      direction: rtl;
      text-align: start;
    }

    .ant-popover-arrow {
      right: inherit;
      left: 12px;
    }

    .notifications-menu-image {
      margin-right: inherit;
      margin-left: 15px;
    }
  }

  .auth-section-footer {
    .user-menu-icon {
      margin-right: inherit;
      margin-left: 10px;
    }
  }

  .refer.only-desktop {
    right: -140px;
    left: inherit;

    .refer-button svg {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  &.layout.auth {
    background-position: -15vw center;
  }

  .q-table_menu.ant-dropdown-trigger {
    right: inherit;
    left: 10px;
  }

  .ant-select-arrow {
    right: inherit;
    left: 11px;
  }

  .BSUnit__edit {
    right: inherit;
    left: 0;
  }

  .BSUnit__list .i {
    margin-right: 0px;
    margin-left: 7px;
  }

  .ant-input-prefix {
    margin-right: 0;
    margin-left: 4px;
  }

  .q-table-list .check-icon {
    margin: 0 0px 0 5px;
  }

  .q-table-product_title {
    text-align: start;
  }

  .q-table-product_thumb {
    margin-right: 0px;
    margin-left: 10px;
  }

  .q-table .ant-table-tbody>tr>td {
    text-align: start;
  }

  .ant-table-thead>tr>th {
    text-align: start;
  }

  .ant-dropdown-menu {
    direction: rtl;
    text-align: start;

    .user-menu-icon {
      margin-top: -1px;
      margin-left: 10px;
    }
  }

  .qu-header-logo {
    svg {
      width: 100px;
    }
  }

  .user-menu {
    margin-left: 0;
    // margin-right: 18px;

    .user-name {
      margin-right: 0;
      // margin-left: 10px;
    }
  }

  //Edit brief containter
  .form-brief {
    .form__number {
      margin-right: inherit;
      margin-left: 16px;
    }
  }

  .qu-brief-footer {
    .footer-center .qu-button:not(:last-child) {
      margin-right: 0;
      margin-left: 10px;
    }

    .footer-right .qu-button {
      right: inherit;
      left: 0;
    }
  }

  .qu-brief-header .header-stepper {
    margin-left: 0;
    margin-right: -174px;
  }

  .qu-brand-label {
    .qu-brand-logo {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  //Cart Summary Card
  .cart-summary__edit {
    float: left;
  }

  .cart-summary__dlist dt {
    float: right;
  }

  .SSUnit__descriptions .label-column small {
    direction: ltr;
    text-align: right;
  }

  .ProfileCard__logo {
    margin-right: 0;
    margin-left: 14px;
  }

  .ProfileCard__services-item:not(:last-child) {
    margin-right: 0;
    margin-left: 10px;
  }

  .ProfileCard__services-item .i {
    margin-right: 0;
    margin-left: 9px;
  }

  @media (min-width: 666px) and (max-width: 1366px) {
    .ProfileCard__services-item .i {
      margin-right: 0;
      margin-left: 4px;
    }
  }

  .ProfileCard__tags {
    margin-right: auto;
    margin-left: inherit;
  }

  .client-preview-container-header .qu-button-wrapper {
    margin-left: inherit;
    margin-right: auto;
  }

  .client-preview-container-percentage {
    margin-left: inherit;
    margin-right: 15px;
  }

  .qu-brief-main {
    margin-right: 0;
    margin-left: 16px;
  }

  .SSUnit {
    .SSUnit__heading {
      padding-right: 0;
      padding-left: 40px;
    }

    .SSUnit__edit {
      left: 0;
      right: inherit;
    }

    .SSUnit__thumb {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  .layout.profile {
    .client-preview-container-body .info-row-key {
      margin-right: 0;
      margin-left: 30px;
    }

    /*.PartnerProfileSidebar div.current .index {
      transform: translate(120%, 0);
    }*/

    .PartnerProfileSidebar div.finished div.index,
    .PartnerProfileSidebar div.unfinished .index {
      transform: translate(120%, 0);
      top: 20%;
    }
  }

  .qu-details-aside {
    .button__counter {
      margin: 0 4px 0 -4px;
    }
  }

  .partner-layout {
    .ant-table-tbody {

      th,
      td {
        text-align: start;
      }
    }
  }

  //campaign details
  .reports_listing {
    margin: 15px;
  }

  .reports_listing div {
    float: left;
  }

  .reports_listing .check_report_icon {
    margin-right: inherit;
    margin-left: 10px;
  }

  .reports_listing .date_span {
    margin-right: inherit;
    margin-left: 27px;
  }

  //pagination
  .qu-pagination {
    direction: ltr;
  }

  //(radio-button) tabs
  .ant-radio-group-solid .qu-radio-button:first-child {
    border-radius: 0 10px 10px 0;
  }

  .ant-radio-group-solid .qu-radio-button:last-child {
    border-radius: 10px 0 0 10px;
  }

  //arrows
  .button__icon {
    transform: rotate(180deg);
  }

  .online_payment_sub_label {
    padding-left: unset !important;
    padding-right: 35px;
  }

  .qu-statistic-item:last-child {
    margin-right: 40px;
  }

  .qu-pagination .ant-pagination-options .ant-select-selector {
    padding-left: 30px !important;
    padding-right: unset !important;
  }

  .qu-select.ant-select-multiple.ant-select-show-arrow .ant-select-selector {
      padding-left: 24px !important;
      padding-right: unset !important;
  }
}
//end html-rtl

.html-rtl.user-menu-list {
  direction: rtl;
  text-align: start;

  .user-menu-icon {
    margin-top: -1px;
    margin-left: 10px;
  }
}

.html-rtl .PartnerProfileSidebar div.finished div.index {
  top: 20%;
  transform: none;
  right: -18px;
}

.html-rtl .PartnerProfileSidebar div.current .index {
  transform: none;
  right: -18px;
}

//dashboard
.html-rtl .ant-radio-group.system-filters {
  width: 346px; 
}