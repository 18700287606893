.brief {
  @media only screen and (max-width: 480px) {
    &-list {
      &-header {
        display: flex;
        margin-bottom: 15px;

        h2 {
          font-size: large;
        }

        +div .qt-search {
          flex: 100%;
          margin: 0 16px;
        }
      }
    }
  }
}

.qu-brief-list .cursorPointer {
  cursor: pointer;
}

.testahel-brief-bg {
  background-color: #6a16e20d !important;
}

.action-required-red-dot {
  height: 6px;
  width: 6px;
  background: red;
  border-radius: 50px;
}

.action-required-white-dot {
  height: 6px;
  width: 6px;
  border-radius: 50px;
}