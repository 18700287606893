.partner {
  display: flex;
  background-color: $q-main-bg;

  &-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-inline-start: 1px solid #E7E7E7;
  }

  &-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 56px);
    height: 100%;
    padding: 0 20px;
    overflow: auto;

    .disabledField {
      z-index: 1000;
      background-color: rgb(246, 231, 231);
      opacity: 0.6;
      pointer-events: none;
    }

    &.print_main {
      max-height: initial;
    }

    .qu-brief-footer .footer-center .footer-btn-width {
      width: 330px !important;
    }

    .dynamic-sample-pricing-label {
      padding: 15px 0 15px 0;
      color: grey;
      font-weight: 500;
      font-size: 15px;
      line-height: 1.2;
      text-decoration: underline;
    }

    .dynamic-sample-price-input-label {
      color: #c6c4c4;
    }
  }

  &-content {
    // max-width: 1200px;
    width: 100%;
    min-height: calc(100vh - 56px);

    > :last-child {
      margin-bottom: 50px;
    }

    .spinner_overlay {
      margin-bottom: 0;
    }
  }

  .qt-body .cursorPointer {
    cursor: pointer;
  }

  & .profile {
    max-height: calc(100vh - 56px);
    height: calc(100vh - 56px);

    &-sidebar {
      .SS .SS__header {
        background-color: #fafbfb;
      }

      .InfoCard {
        margin: 0;
      }
    }

    &-footer_custom {
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 35px;

      & .right {
        display: flex;

        .qu-button {
          min-width: 150px;
        }
      }

      & .left {
        display: flex;
      }

      .qu-button {
        min-width: 150px;
      }
    }

    &-center_custom {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    @media only screen and (max-width: 666px) {
      .productTypeLeft {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .line {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
      }

      &-sidebar {
        display: none;
      }

      .profile-main {
        margin: 0 20px;
      }

      >.qu-sidebar {
        display: none;
      }

      &-footer_custom {
        flex-direction: row-reverse;
        margin-top: 0;

        & .right {
          flex: 1;

          .qu-button {
            flex: 1;
            min-width: 150px;

            &:not(:first-child) {
              margin: 0 10px 10px 10px;
            }
          }
        }

        & .left {
          flex: 1;
          order: 1;
          margin-top: 0;

          .qu-button {
            flex: 1;
            min-width: 150px;

            &:not(:first-child) {
              margin: 0 0 0 10px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      &-main {
        align-items: flex-start;
      }
    }
  }

  @media only screen and (max-width: 666px) {
    >.qu-sidebar {
      display: none;
    }
  }

  @media only screen and (min-width: 1440px) {
    &-main {
      align-items: flex-start;
    }

    &-content {
      //margin-left: 50px;
      margin: 0 auto;
      width: calc(100% - 50px) !important;
    }
  }

  .tax-treatment {
    .ant-radio-group {
      width: 100%;

      .qu-radio {
        margin-bottom: 5px;
        margin-right: unset;
      }
    }
  }
}